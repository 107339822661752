@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,500);
@import url(icons.css);
.floating-label-form-group .form-submit:hover~.icon-form,
a {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out
}
body{
	min-height:100vh
}
body,
figure {
	margin: 0
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse,
.pre-scrollable {
	max-height: 340px
}

.img-thumbnail,
.table,
label {
	max-width: 100%
}

.flx-dir-row,
.flx-dir-row-rev {
	-webkit-box-orient: horizontal
}

.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.row:after {
	clear: both
}

@media screen and (max-width:767px) {
	.no-border-xs {
		border: none!important
	}
}

hr,
img {
	border: 0
}

.flx {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex
}

.flx-inline {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex
}

html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline
}

audio:not([controls]) {
	display: none;
	height: 0
}

[hidden],
template {
	display: none
}

a {
	background-color: transparent
}

a:active,
a:hover {
	outline: 0
}

b,
strong {
	font-weight: 500
}

dfn {
	font-style: italic
}

h1 {
	font-size: 2em;
	margin: .67em 0
}

mark {
	background: #ff0;
	color: #000
}

sub,
sup {
	font-size: 55%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub.large,
sup.large {
	font-size: 75%;
	top: -.3em
}

sup {
	top: -.6em
}

sub {
	bottom: -.25em
}

h1 sup {
	font-size: 40%;
	top: -1.1em
}

img {
	vertical-align: middle
}

svg:not(:root) {
	overflow: hidden
}

hr {
	box-sizing: content-box;
	height: 0
}

pre,
textarea {
	overflow: auto
}

code,
kbd,
pre,
samp {
	font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0
}

.btn,
body {
	-webkit-font-smoothing: antialiased
}

button {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
	-webkit-appearance: button;
	cursor: pointer
}

button[disabled],
html input[disabled] {
	cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0
}

input[type=checkbox],
input[type=radio] {
	box-sizing: border-box;
	padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	height: auto
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	-webkit-appearance: none
}

optgroup {
	font-weight: 700
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

td,
th {
	padding: 0
}


/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

@media print {
	blockquote,
	img,
	pre,
	tr {
		page-break-inside: avoid
	}
	*,
	:after,
	:before {
		background: 0 0!important;
		color: #000!important;
		box-shadow: none!important;
		text-shadow: none!important
	}
	a,
	a:visited {
		text-decoration: underline
	}
	a[href]:after {
		content: " (" attr(href) ")"
	}
	abbr[title]:after {
		content: " (" attr(title) ")"
	}
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}
	blockquote,
	pre {
		border: 1px solid #999
	}
	thead {
		display: table-header-group
	}
	img {
		max-width: 100%!important
	}
	h2,
	h3,
	p {
		orphans: 3;
		widows: 3
	}
	h2,
	h3 {
		page-break-after: avoid
	}
	.navbar {
		display: none
	}
	.btn>.caret,
	.dropup>.btn>.caret {
		border-top-color: #000!important
	}
	.label {
		border: 1px solid #000
	}
	.table {
		border-collapse: collapse!important
	}
	.table td,
	.table th {
		background-color: #fff!important
	}
	.table-bordered td,
	.table-bordered th {
		border: 1px solid #ddd!important
	}
}

.img-thumbnail,
body {
	background-color: #f8f8f8
}

*,
:after,
:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

html {
	font-size: 10px;
	-webkit-tap-highlight-color: transparent
}

body {
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: #575756;
	-moz-osx-font-smoothing: grayscale
}

@media (max-width:767px) {
	body {
		font-size: 14px
	}
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

a {
	color: #406EB3;
	text-decoration: none;
	transition: all .2s ease-in-out
}

a:focus,
a:hover {
	color: #2c4b7b;
	text-decoration: none;
	outline: 0
}

hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border-top: 1px solid #ddd
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto
}

[role=button] {
	cursor: pointer
}

.bldt,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: inherit;
	color: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-top: 0
}

h6 {
	margin-bottom: 10px
}

.bldt .small,
.bldt small,
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
	font-size: 65%
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
	font-size: 85%
}

.h1,
.h2,
.h3,
.h5,
.h6,
h1,
h2,
h3,
h5,
h6 {
	font-weight: 500;
	margin-bottom: 10px
}

a .bldt,
a .h1,
a .h2,
a .h3,
a .h4,
a .h5,
a .h6,
a h1,
a h2,
a h3,
a h4,
a h5,
a h6,
a p {
	color: #575756
}

.h4,
h4 {
	font-weight: 400;
	margin-bottom: 10px
}

.bldt {
	font-weight: 500
}

.bldt span {
	font-weight: 400
}

@media (min-width:992px) {
	.h1,
	h1 {
		font-size: 48px;
		line-height: 58px
	}
	.h2,
	h2 {
		font-size: 36px;
		line-height: 43px
	}
	.h3,
	h3 {
		font-size: 28px;
		line-height: 34px
	}
	.h4,
	.h5,
	h4,
	h5 {
		font-size: 20px;
		line-height: 28px
	}
	.h6,
	h6 {
		font-size: 16px;
		line-height: 24px
	}
}

@media (min-width:768px) and (max-width:991px) {
	.h1,
	h1 {
		font-size: 40px;
		line-height: 48px
	}
	.h2,
	h2 {
		font-size: 32px;
		line-height: 38px
	}
	.h3,
	h3 {
		font-size: 24px;
		line-height: 29px
	}
	.h4,
	.h5,
	h4,
	h5 {
		font-size: 18px;
		line-height: 28px
	}
	.h6,
	h6 {
		font-size: 16px;
		line-height: 24px
	}
}

@media (max-width:767px) {
	.h1,
	.h2,
	h1,
	h2 {
		font-size: 28px;
		line-height: 34px
	}
	.h3,
	h3 {
		font-size: 20px;
		line-height: 24px
	}
	.h4,
	.h5,
	h4,
	h5 {
		font-size: 16px;
		line-height: 24px
	}
	.h6,
	h6 {
		font-size: 14px;
		line-height: 21px
	}
}

p {
	margin: 0 0 10px
}

.small,
small {
	font-size: 85%
}

.mark,
mark {
	background-color: #fcf8e3;
	padding: .2em
}

pre code,
table {
	background-color: transparent
}

abbr[data-original-title],
abbr[title] {
	cursor: help;
	border-bottom: 1px dotted #f4f4f4
}

.form-control[disabled],
.radio input[type=radio]:disabled+label:before,
fieldset[disabled] .form-control {
	cursor: not-allowed
}

blockquote {
	padding: 9.5px 19px;
	margin: 0 0 19px;
	font-size: 20px;
	border-left: 5px solid #f8f8f8
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
	margin-bottom: 0
}

blockquote .small,
blockquote footer,
blockquote small {
	display: block;
	font-size: 80%;
	line-height: 1.5;
	color: #f4f4f4
}

legend,
pre {
	color: #242423
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
	content: '\2014 \00A0'
}

.blockquote-reverse,
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #f8f8f8;
	border-left: 0;
	text-align: right
}

code,
kbd {
	padding: 2px 4px;
	font-size: 90%;
	border-radius: 6px
}

caption,
th {
	text-align: left
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
	content: ''
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
	content: '\00A0 \2014'
}

.global-box-shadow {
	box-shadow: 0 3px 10px rgba(0, 0, 0, .2)
}

.global-box-shadow-light {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1)
}

code,
kbd,
pre,
samp {
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
	color: #c7254e;
	background-color: #f9f2f4
}

kbd {
	color: #fff;
	background-color: #333;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25)
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700;
	box-shadow: none
}

pre {
	display: block;
	padding: 9px;
	margin: 0 0 9.5px;
	font-size: 15px;
	line-height: 1.5;
	word-break: break-all;
	word-wrap: break-word;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 6px
}

.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.row:after,
.row:before {
	content: " ";
	display: table
}

.container,
.container-fluid {
	margin-right: auto;
	margin-left: auto
}

.pre-scrollable {
	overflow-y: scroll
}

.container {
	padding: 80px 15px
}

@media (max-width:767px) {
	.container {
		padding-top: 40px;
		padding-bottom: 40px;
		width: 100%;
	}
}

@media (min-width:768px) and (max-width:991px) {
	.container {
		width: 750px;
		padding-top: 40px;
		padding-bottom: 40px
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.container {
		width: 970px
	}
}

@media (min-width:1200px) {
	.container {
		width: 1200px;
		padding-left: 35px;
		padding-right: 35px
	}
}

.container-fluid {
	padding: 80px 15px
}

.row {
	margin-left: -15px;
	margin-right: -15px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	float: left
}

.col-xs-1 {
	width: 8.33333%
}

.col-xs-2 {
	width: 16.66667%
}

.col-xs-3 {
	width: 25%
}

.col-xs-4 {
	width: 33.33333%
}

.col-xs-5 {
	width: 41.66667%
}

.col-xs-6 {
	width: 50%
}

.col-xs-7 {
	width: 58.33333%
}

.col-xs-8 {
	width: 66.66667%
}

.col-xs-9 {
	width: 75%
}

.col-xs-10 {
	width: 83.33333%
}

.col-xs-11 {
	width: 91.66667%
}

.col-xs-12 {
	width: 100%
}

.col-xs-pull-0 {
	right: auto
}

.col-xs-pull-1 {
	right: 8.33333%
}

.col-xs-pull-2 {
	right: 16.66667%
}

.col-xs-pull-3 {
	right: 25%
}

.col-xs-pull-4 {
	right: 33.33333%
}

.col-xs-pull-5 {
	right: 41.66667%
}

.col-xs-pull-6 {
	right: 50%
}

.col-xs-pull-7 {
	right: 58.33333%
}

.col-xs-pull-8 {
	right: 66.66667%
}

.col-xs-pull-9 {
	right: 75%
}

.col-xs-pull-10 {
	right: 83.33333%
}

.col-xs-pull-11 {
	right: 91.66667%
}

.col-xs-pull-12 {
	right: 100%
}

.col-xs-push-0 {
	left: auto
}

.col-xs-push-1 {
	left: 8.33333%
}

.col-xs-push-2 {
	left: 16.66667%
}

.col-xs-push-3 {
	left: 25%
}

.col-xs-push-4 {
	left: 33.33333%
}

.col-xs-push-5 {
	left: 41.66667%
}

.col-xs-push-6 {
	left: 50%
}

.col-xs-push-7 {
	left: 58.33333%
}

.col-xs-push-8 {
	left: 66.66667%
}

.col-xs-push-9 {
	left: 75%
}

.col-xs-push-10 {
	left: 83.33333%
}

.col-xs-push-11 {
	left: 91.66667%
}

.col-xs-push-12 {
	left: 100%
}

.col-xs-offset-0 {
	margin-left: 0
}

.col-xs-offset-1 {
	margin-left: 8.33333%
}

.col-xs-offset-2 {
	margin-left: 16.66667%
}

.col-xs-offset-3 {
	margin-left: 25%
}

.col-xs-offset-4 {
	margin-left: 33.33333%
}

.col-xs-offset-5 {
	margin-left: 41.66667%
}

.col-xs-offset-6 {
	margin-left: 50%
}

.col-xs-offset-7 {
	margin-left: 58.33333%
}

.col-xs-offset-8 {
	margin-left: 66.66667%
}

.col-xs-offset-9 {
	margin-left: 75%
}

.col-xs-offset-10 {
	margin-left: 83.33333%
}

.col-xs-offset-11 {
	margin-left: 91.66667%
}

.col-xs-offset-12 {
	margin-left: 100%
}

@media (min-width:768px) {
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9 {
		float: left
	}
	.col-sm-1 {
		width: 8.33333%
	}
	.col-sm-2 {
		width: 16.66667%
	}
	.col-sm-3 {
		width: 25%
	}
	.col-sm-4 {
		width: 33.33333%
	}
	.col-sm-5 {
		width: 41.66667%
	}
	.col-sm-6 {
		width: 50%
	}
	.col-sm-7 {
		width: 58.33333%
	}
	.col-sm-8 {
		width: 66.66667%
	}
	.col-sm-9 {
		width: 75%
	}
	.col-sm-10 {
		width: 83.33333%
	}
	.col-sm-11 {
		width: 91.66667%
	}
	.col-sm-12 {
		width: 100%
	}
	.col-sm-pull-0 {
		right: auto
	}
	.col-sm-pull-1 {
		right: 8.33333%
	}
	.col-sm-pull-2 {
		right: 16.66667%
	}
	.col-sm-pull-3 {
		right: 25%
	}
	.col-sm-pull-4 {
		right: 33.33333%
	}
	.col-sm-pull-5 {
		right: 41.66667%
	}
	.col-sm-pull-6 {
		right: 50%
	}
	.col-sm-pull-7 {
		right: 58.33333%
	}
	.col-sm-pull-8 {
		right: 66.66667%
	}
	.col-sm-pull-9 {
		right: 75%
	}
	.col-sm-pull-10 {
		right: 83.33333%
	}
	.col-sm-pull-11 {
		right: 91.66667%
	}
	.col-sm-pull-12 {
		right: 100%
	}
	.col-sm-push-0 {
		left: auto
	}
	.col-sm-push-1 {
		left: 8.33333%
	}
	.col-sm-push-2 {
		left: 16.66667%
	}
	.col-sm-push-3 {
		left: 25%
	}
	.col-sm-push-4 {
		left: 33.33333%
	}
	.col-sm-push-5 {
		left: 41.66667%
	}
	.col-sm-push-6 {
		left: 50%
	}
	.col-sm-push-7 {
		left: 58.33333%
	}
	.col-sm-push-8 {
		left: 66.66667%
	}
	.col-sm-push-9 {
		left: 75%
	}
	.col-sm-push-10 {
		left: 83.33333%
	}
	.col-sm-push-11 {
		left: 91.66667%
	}
	.col-sm-push-12 {
		left: 100%
	}
	.col-sm-offset-0 {
		margin-left: 0
	}
	.col-sm-offset-1 {
		margin-left: 8.33333%
	}
	.col-sm-offset-2 {
		margin-left: 16.66667%
	}
	.col-sm-offset-3 {
		margin-left: 25%
	}
	.col-sm-offset-4 {
		margin-left: 33.33333%
	}
	.col-sm-offset-5 {
		margin-left: 41.66667%
	}
	.col-sm-offset-6 {
		margin-left: 50%
	}
	.col-sm-offset-7 {
		margin-left: 58.33333%
	}
	.col-sm-offset-8 {
		margin-left: 66.66667%
	}
	.col-sm-offset-9 {
		margin-left: 75%
	}
	.col-sm-offset-10 {
		margin-left: 83.33333%
	}
	.col-sm-offset-11 {
		margin-left: 91.66667%
	}
	.col-sm-offset-12 {
		margin-left: 100%
	}
}

@media (min-width:992px) {
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9 {
		float: left
	}
	.col-md-1 {
		width: 8.33333%
	}
	.col-md-2 {
		width: 16.66667%
	}
	.col-md-3 {
		width: 25%
	}
	.col-md-4 {
		width: 33.33333%
	}
	.col-md-5 {
		width: 41.66667%
	}
	.col-md-6 {
		width: 50%
	}
	.col-md-7 {
		width: 58.33333%
	}
	.col-md-8 {
		width: 66.66667%
	}
	.col-md-9 {
		width: 75%
	}
	.col-md-10 {
		width: 83.33333%
	}
	.col-md-11 {
		width: 91.66667%
	}
	.col-md-12 {
		width: 100%
	}
	.col-md-pull-0 {
		right: auto
	}
	.col-md-pull-1 {
		right: 8.33333%
	}
	.col-md-pull-2 {
		right: 16.66667%
	}
	.col-md-pull-3 {
		right: 25%
	}
	.col-md-pull-4 {
		right: 33.33333%
	}
	.col-md-pull-5 {
		right: 41.66667%
	}
	.col-md-pull-6 {
		right: 50%
	}
	.col-md-pull-7 {
		right: 58.33333%
	}
	.col-md-pull-8 {
		right: 66.66667%
	}
	.col-md-pull-9 {
		right: 75%
	}
	.col-md-pull-10 {
		right: 83.33333%
	}
	.col-md-pull-11 {
		right: 91.66667%
	}
	.col-md-pull-12 {
		right: 100%
	}
	.col-md-push-0 {
		left: auto
	}
	.col-md-push-1 {
		left: 8.33333%
	}
	.col-md-push-2 {
		left: 16.66667%
	}
	.col-md-push-3 {
		left: 25%
	}
	.col-md-push-4 {
		left: 33.33333%
	}
	.col-md-push-5 {
		left: 41.66667%
	}
	.col-md-push-6 {
		left: 50%
	}
	.col-md-push-7 {
		left: 58.33333%
	}
	.col-md-push-8 {
		left: 66.66667%
	}
	.col-md-push-9 {
		left: 75%
	}
	.col-md-push-10 {
		left: 83.33333%
	}
	.col-md-push-11 {
		left: 91.66667%
	}
	.col-md-push-12 {
		left: 100%
	}
	.col-md-offset-0 {
		margin-left: 0
	}
	.col-md-offset-1 {
		margin-left: 8.33333%
	}
	.col-md-offset-2 {
		margin-left: 16.66667%
	}
	.col-md-offset-3 {
		margin-left: 25%
	}
	.col-md-offset-4 {
		margin-left: 33.33333%
	}
	.col-md-offset-5 {
		margin-left: 41.66667%
	}
	.col-md-offset-6 {
		margin-left: 50%
	}
	.col-md-offset-7 {
		margin-left: 58.33333%
	}
	.col-md-offset-8 {
		margin-left: 66.66667%
	}
	.col-md-offset-9 {
		margin-left: 75%
	}
	.col-md-offset-10 {
		margin-left: 83.33333%
	}
	.col-md-offset-11 {
		margin-left: 91.66667%
	}
	.col-md-offset-12 {
		margin-left: 100%
	}
}

@media (min-width:1200px) {
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9 {
		float: left
	}
	.col-lg-1 {
		width: 8.33333%
	}
	.col-lg-2 {
		width: 16.66667%
	}
	.col-lg-3 {
		width: 25%
	}
	.col-lg-4 {
		width: 33.33333%
	}
	.col-lg-5 {
		width: 41.66667%
	}
	.col-lg-6 {
		width: 50%
	}
	.col-lg-7 {
		width: 58.33333%
	}
	.col-lg-8 {
		width: 66.66667%
	}
	.col-lg-9 {
		width: 75%
	}
	.col-lg-10 {
		width: 83.33333%
	}
	.col-lg-11 {
		width: 91.66667%
	}
	.col-lg-12 {
		width: 100%
	}
	.col-lg-pull-0 {
		right: auto
	}
	.col-lg-pull-1 {
		right: 8.33333%
	}
	.col-lg-pull-2 {
		right: 16.66667%
	}
	.col-lg-pull-3 {
		right: 25%
	}
	.col-lg-pull-4 {
		right: 33.33333%
	}
	.col-lg-pull-5 {
		right: 41.66667%
	}
	.col-lg-pull-6 {
		right: 50%
	}
	.col-lg-pull-7 {
		right: 58.33333%
	}
	.col-lg-pull-8 {
		right: 66.66667%
	}
	.col-lg-pull-9 {
		right: 75%
	}
	.col-lg-pull-10 {
		right: 83.33333%
	}
	.col-lg-pull-11 {
		right: 91.66667%
	}
	.col-lg-pull-12 {
		right: 100%
	}
	.col-lg-push-0 {
		left: auto
	}
	.col-lg-push-1 {
		left: 8.33333%
	}
	.col-lg-push-2 {
		left: 16.66667%
	}
	.col-lg-push-3 {
		left: 25%
	}
	.col-lg-push-4 {
		left: 33.33333%
	}
	.col-lg-push-5 {
		left: 41.66667%
	}
	.col-lg-push-6 {
		left: 50%
	}
	.col-lg-push-7 {
		left: 58.33333%
	}
	.col-lg-push-8 {
		left: 66.66667%
	}
	.col-lg-push-9 {
		left: 75%
	}
	.col-lg-push-10 {
		left: 83.33333%
	}
	.col-lg-push-11 {
		left: 91.66667%
	}
	.col-lg-push-12 {
		left: 100%
	}
	.col-lg-offset-0 {
		margin-left: 0
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%
	}
	.col-lg-offset-3 {
		margin-left: 25%
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%
	}
	.col-lg-offset-6 {
		margin-left: 50%
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%
	}
	.col-lg-offset-9 {
		margin-left: 75%
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%
	}
	.col-lg-offset-12 {
		margin-left: 100%
	}
}

@media (min-width:1440px) {
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xs-1,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9 {
		position: relative;
		min-height: 1px;
		padding-left: 20px;
		padding-right: 20px
	}
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9 {
		float: left
	}
	.col-xl-1 {
		width: 8.33333%
	}
	.col-xl-2 {
		width: 16.66667%
	}
	.col-xl-3 {
		width: 25%
	}
	.col-xl-4 {
		width: 33.33333%
	}
	.col-xl-5 {
		width: 41.66667%
	}
	.col-xl-6 {
		width: 50%
	}
	.col-xl-7 {
		width: 58.33333%
	}
	.col-xl-8 {
		width: 66.66667%
	}
	.col-xl-9 {
		width: 75%
	}
	.col-xl-10 {
		width: 83.33333%
	}
	.col-xl-11 {
		width: 91.66667%
	}
	.col-xl-12 {
		width: 100%
	}
	.col-xl-pull-0 {
		right: auto
	}
	.col-xl-pull-1 {
		right: 8.33333%
	}
	.col-xl-pull-2 {
		right: 16.66667%
	}
	.col-xl-pull-3 {
		right: 25%
	}
	.col-xl-pull-4 {
		right: 33.33333%
	}
	.col-xl-pull-5 {
		right: 41.66667%
	}
	.col-xl-pull-6 {
		right: 50%
	}
	.col-xl-pull-7 {
		right: 58.33333%
	}
	.col-xl-pull-8 {
		right: 66.66667%
	}
	.col-xl-pull-9 {
		right: 75%
	}
	.col-xl-pull-10 {
		right: 83.33333%
	}
	.col-xl-pull-11 {
		right: 91.66667%
	}
	.col-xl-pull-12 {
		right: 100%
	}
	.col-xl-push-0 {
		left: auto
	}
	.col-xl-push-1 {
		left: 8.33333%
	}
	.col-xl-push-2 {
		left: 16.66667%
	}
	.col-xl-push-3 {
		left: 25%
	}
	.col-xl-push-4 {
		left: 33.33333%
	}
	.col-xl-push-5 {
		left: 41.66667%
	}
	.col-xl-push-6 {
		left: 50%
	}
	.col-xl-push-7 {
		left: 58.33333%
	}
	.col-xl-push-8 {
		left: 66.66667%
	}
	.col-xl-push-9 {
		left: 75%
	}
	.col-xl-push-10 {
		left: 83.33333%
	}
	.col-xl-push-11 {
		left: 91.66667%
	}
	.col-xl-push-12 {
		left: 100%
	}
	.col-xl-offset-0 {
		margin-left: 0
	}
	.col-xl-offset-1 {
		margin-left: 8.33333%
	}
	.col-xl-offset-2 {
		margin-left: 16.66667%
	}
	.col-xl-offset-3 {
		margin-left: 25%
	}
	.col-xl-offset-4 {
		margin-left: 33.33333%
	}
	.col-xl-offset-5 {
		margin-left: 41.66667%
	}
	.col-xl-offset-6 {
		margin-left: 50%
	}
	.col-xl-offset-7 {
		margin-left: 58.33333%
	}
	.col-xl-offset-8 {
		margin-left: 66.66667%
	}
	.col-xl-offset-9 {
		margin-left: 75%
	}
	.col-xl-offset-10 {
		margin-left: 83.33333%
	}
	.col-xl-offset-11 {
		margin-left: 91.66667%
	}
	.col-xl-offset-12 {
		margin-left: 100%
	}
}

caption {
	padding-top: 8px;
	padding-bottom: 8px;
	color: #f4f4f4
}

fieldset,
legend {
	padding: 0;
	border: 0
}

fieldset {
	margin: 0;
	min-width: 0
}

legend {
	display: block;
	width: 100%;
	margin-bottom: 19px;
	font-size: 24px;
	line-height: inherit;
	border-bottom: 1px solid #e5e5e5
}

label {
	display: inline-block;
	margin-bottom: 5px;
	font-weight: 500
}

input[type=search] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none
}

input[type=checkbox],
input[type=radio] {
	margin: 4px 0 0;
	margin-top: 1px\9;
	line-height: normal
}

.checkbox.checkbox-inline,
.radio.radio-inline {
	margin-top: 0
}

.form-control,
output {
	display: block;
	font-size: 16px;
	line-height: 1.5;
	color: #575756
}

input[type=file] {
	display: block
}

input[type=range] {
	display: block;
	width: 100%
}

select[multiple],
select[size] {
	height: auto
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
	outline: -webkit-focus-ring-color auto 5px;
	outline-offset: -2px
}

.btn,
.checkbox input[type=checkbox]:focus+label:before,
.checkbox input[type=radio]:focus+label:before,
.floating-label-form-group input:focus,
.form-control:focus,
.radio input[type=radio]:focus+label:before {
	outline: 0
}

output {
	padding-top: 14px
}
select.form-control {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-control {
	width: 100%;
	height: 42px;
	padding: 10px 20px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 6px;
	-webkit-transition: border-color ease-in-out .15s;
	-moz-transition: border-color ease-in-out .15s;
	-ms-transition: border-color ease-in-out .15s;
	-o-transition: border-color ease-in-out .15st;
	transition: border-color ease-in-out .15s;
}


.form-control:focus {
	border-color: #2ECC72;
	-webkit-box-shadow: none;
	box-shadow: none
}

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1
}

.form-control:-ms-input-placeholder {
	color: #999
}

.form-control::-webkit-input-placeholder {
	color: #999
}

.form-control::-ms-expand {
	border: 0;
	background-color: transparent
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #f8f8f8;
	opacity: 1
}

textarea.form-control {
	height: auto
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	input[type=date].form-control,
	input[type=datetime-local].form-control,
	input[type=month].form-control,
	input[type=time].form-control {
		line-height: 50px
	}
	.input-group-sm input[type=date],
	.input-group-sm input[type=datetime-local],
	.input-group-sm input[type=month],
	.input-group-sm input[type=time],
	input[type=date].input-sm,
	input[type=datetime-local].input-sm,
	input[type=month].input-sm,
	input[type=time].input-sm {
		line-height: 40px
	}
	input[type=date].input-lg,
	input[type=datetime-local].input-lg,
	input[type=month].input-lg,
	input[type=time].input-lg {
		line-height: 60px
	}
}

.form-group {
	margin-bottom: 15px
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
	top: -2px;
	height: 20px;
	width: 20px
}

.checkbox label,
.radio label,
.multiselect-container label {
	display: inline-block;
	vertical-align: middle;
	position: relative
}

.checkbox label:after,
.checkbox label:before,
.radio label:after,
.radio label:before {
	display: inline-block;
	position: absolute;
	margin-left: -20px
}

.multiselect-container label:after,
.multiselect-container label:before {
	display: inline-block;
	position: absolute;
	margin-left: 0px
}

.checkbox label:before,
.multiselect-container label:before {
	content: "";
	width: 20px;
	height: 20px;
	left: 0;
	border: 1px solid #575756;
	border-radius: 2px;
	background-color: none;
	-webkit-transition: border 80ms ease-in-out, color 80ms ease-in-out;
	-o-transition: border 80ms ease-in-out, color 80ms ease-in-out;
	transition: border 80ms ease-in-out, color 80ms ease-in-out;
	top: 2px
}

.checkbox label:after,
.multiselect-container label:after {
	width: 20px;
	height: 20px;
	left: 3px;
	top: 2px;
	font-size: 14px
}

.checkbox input[type=checkbox],
.checkbox input[type=radio],
.radio input[type=radio] {
	opacity: 0;
	/*z-index: 1*/
}

.multiselect-container input[type=checkbox] {
	opacity: 0;
	z-index: 1
}

.checkbox input[type=checkbox]:checked~label:after,
.multiselect-container li.active label:after {
	font-family: icomoon;
	content: "\e902";
	color: #FFF!important;
	font-weight: 700
}

.radio label:after,
.radio label:before {
	content: ""
}

.checkbox.checkbox-circle label:before {
	border-radius: 50%
}

.checkbox input[type=checkbox]:checked~label:before,
.checkbox input[type=radio]:checked~label:before,
.multiselect-container li.active label:before {
	background-color: #2ECC72;
	border-color: #2ECC72
}

.checkbox input[type=checkbox]:checked~label:after,
.checkbox input[type=radio]:checked~label:after,
.multiselect-container input[type=checkbox]:checked~label:after,
.multiselect-container input[type=radio]:checked~label:after {
	color: none
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .form-control-feedback,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
	color: #2FBA78
}

.radio label:after {
	width: 12px;
	height: 12px;
	left: 4px;
	top: 6px;
	border-radius: 50%;
	background: #2ECC72;
	-webkit-transform: scale(0, 0);
	-ms-transform: scale(0, 0);
	-o-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: -webkit-transform .1s cubic-bezier(.8, -.33, .2, 1.33);
	-moz-transition: -moz-transform .1s cubic-bezier(.8, -.33, .2, 1.33);
	-o-transition: -o-transform .1s cubic-bezier(.8, -.33, .2, 1.33);
	transition: transform .1s cubic-bezier(.8, -.33, .2, 1.33)
}

.radio label:before {
	width: 20px;
	height: 20px;
	left: 0;
	border: 1px solid #575756;
	border-radius: 50%;
	background-color: none;
	-webkit-transition: border 80ms ease-in-out, color 80ms ease-in-out;
	-o-transition: border 80ms ease-in-out, color 80ms ease-in-out;
	transition: border 80ms ease-in-out, color 80ms ease-in-out;
	top: 2px
}

.radio input[type=radio]:checked~label:before {
	border: 1px solid #2ECC72
}

.radio input[type=radio]:disabled+label {
	opacity: .65
}

.radio input[type=radio]:checked~label:after {
	transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
	transition: transform .1s cubic-bezier(.8, -.33, .2, 1.33) 0s;
	-webkit-transition: transform .1s cubic-bezier(.8, -.33, .2, 1.33) 0s
}

.checkbox,
.radio {
	padding-left: 20px;
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 310px;
}

.multiselect-container .checkbox {
	padding-left: 30px;
}

.checkbox label,
.radio label {
	min-height: 19px;
	padding-left: 14px;
	margin-bottom: 0;
	font-weight: inherit;
	cursor: pointer
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
	position: absolute;
	margin-left: -20px;
	width: 100%;
	cursor: pointer;
}

.checkbox+.checkbox,
.radio+.radio {
	margin-top: -5px
}

.checkbox-inline,
.radio-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: 400;
	cursor: pointer
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
	margin-top: 0;
	margin-left: 10px
}

.btn-block+.btn-block,
.help-block {
	margin-top: 5px
}

.checkbox-inline.disabled,
.checkbox.disabled label,
.radio-inline.disabled,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio label,
fieldset[disabled] .radio-inline,
fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
	cursor: not-allowed
}

.disabled-form-wrap {
	background: #f8f8f8;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 0 25px;
	height: 42px;
	margin: 20px 0
}

.form-control-static {
	padding-top: 14px;
	padding-bottom: 14px;
	margin-bottom: 0;
	min-height: 35px
}

.form-control-static.input-lg,
.form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0
}

.input-sm {
	height: 40px;
	padding: 10px 20px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 6px
}

select.input-sm {
	height: 40px;
	line-height: 40px
}

select[multiple].input-sm,
textarea.input-sm {
	height: auto
}

.form-group-sm .form-control {
	height: 40px;
	padding: 0 35px 0 0;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 6px
}

@media (max-width:767px) {
	.form-group-sm .form-control {
		padding: 0 30px 0 0;
	}
}

.form-group-sm select.form-control {
	height: 40px;
	line-height: 40px
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
	height: auto
}

.form-group-sm .form-control-static {
	height: 40px;
	min-height: 31px;
	padding: 11px 20px;
	font-size: 12px;
	line-height: 1.5
}

.form-group-sm label {
	font-size: .75em!important
}

.form-group-sm.floating-label-form-group-with-value input {
	top: 8px!important
}

.form-group-sm.floating-label-form-group-with-value label {
	top: 2px!important
}
.floating-label-form-group-with-focus.form-group-sm.floating-label-form-group-with-value label{
	
} 


.input-lg {
	height: 60px;
	padding: 12px 50px;
	font-size: 20px;
	line-height: 1.4;
	border-radius: 50px
}

select.input-lg {
	height: 60px;
	line-height: 60px
}

select[multiple].input-lg,
textarea.input-lg {
	height: auto
}

.form-group-lg .form-control {
	height: 60px;
	padding: 0;
	font-size: 20px;
	line-height: 1.4
}

.form-group-lg select.form-control {
	height: 60px;
	line-height: 60px
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
	height: auto
}

.form-group-lg .form-control-static {
	height: 60px;
	min-height: 39px;
	padding: 13px 50px;
	font-size: 20px;
	line-height: 1.4
}

.form-group-lg label {
	font-size: .85em!important
}

.form-group-lg.floating-label-form-group-with-value input {
	top: 8px!important
}

.has-feedback {
	position: relative
}

.has-feedback .form-control {
	padding-right: 62.5px
}

.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-lg+.form-control-feedback {
	width: 60px;
	height: 60px;
	line-height: 60px
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-sm+.form-control-feedback {
	width: 40px;
	height: 40px;
	line-height: 40px
}

.has-success .form-control {
	border-color: #2FBA78;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
	border-color: #25915e;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #73dcaa;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #73dcaa
}

.has-success .input-group-addon {
	color: #2FBA78;
	border-color: #2FBA78;
	background-color: #dff0d8
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .form-control-feedback,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
	color: #8a6d3b
}

.has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3
}
.has-error label,
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .form-control-feedback,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
	color: #a94442
}

.has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede
}

.has-feedback label~.form-control-feedback {
	top: 24px
}

.has-feedback label.sr-only~.form-control-feedback {
	top: 0
}

.help-block {
	display: block;
	margin-bottom: 10px;
	color: #979796
}

@media (min-width:768px) {
	.form-inline .form-control-static,
	.form-inline .form-group {
		display: inline-block
	}
	.form-inline .control-label,
	.form-inline .form-group {
		margin-bottom: 0;
		vertical-align: middle
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle
	}
	.form-inline .input-group .form-control,
	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn {
		width: auto
	}
	.form-inline .input-group>.form-control {
		width: 100%
	}
	.form-inline .checkbox,
	.form-inline .radio {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle
	}
	.form-inline .checkbox label,
	.form-inline .radio label {
		padding-left: 0
	}
	.form-inline .checkbox input[type=checkbox],
	.form-inline .radio input[type=radio] {
		position: relative;
		margin-left: 0
	}
	.form-inline .has-feedback .form-control-feedback {
		top: 0
	}
	.form-horizontal .control-label {
		text-align: right;
		margin-bottom: 0;
		padding-top: 14px
	}
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 14px
}

.form-horizontal .checkbox,
.form-horizontal .radio {
	min-height: 33px
}

.form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px
}

.form-horizontal .form-group:after,
.form-horizontal .form-group:before {
	content: " ";
	display: table
}

.form-horizontal .has-feedback .form-control-feedback {
	right: 15px
}

@media (min-width:768px) {
	.form-horizontal .form-group-lg .control-label {
		padding-top: 13px;
		font-size: 20px
	}
	.form-horizontal .form-group-sm .control-label {
		padding-top: 11px;
		font-size: 12px
	}
	.floating-label-form-group.form-group-sm .icon-form {
		right: 15px;
	}
}

.form-group-sm input,
.floating-label-form-group input,
.form-group-sm input textarea,
.floating-label-form-group textarea {
	background: none;
	border: none;
	border-radius: 0;
	padding-left: 0;
	padding-right: 0;
	box-shadow: none!important;
	position: relative;
	resize: none
}

.form-group-sm,
.floating-label-form-group {
	margin: 20px 0;
	padding: 0 25px;
	border: 1px solid #ddd;
	background: #fff;
	border-radius: 6px;
	position: relative
}

@media (max-width:767px) {
	.form-group-sm,
	.floating-label-form-group {
		padding: 0 15px;
	}
	.form-group-sm .form-control {
		padding: 0 30px 0 0;
	}
}

.form-group-sm label,
.floating-label-form-group label {
	display: block;
	position: absolute;
	top: 2em;
	opacity: 0;
	z-index: 0;
	font-size: 12px;
	line-height: 1.5em;
	vertical-align: middle;
	vertical-align: baseline;
	margin: 0;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}

.btn,
.btn-icon-back {
	vertical-align: middle
}

.form-group-sm .icon-form,
.floating-label-form-group .icon-form {
	position: absolute;
	color: #2ECC72;
}

.floating-label-form-group-with-focus {
	border: 1px solid #2ECC72
}

.form-group-sm:focus {
	border: 1px solid #2ECC72;
	outline:none
}

.form-group-sm .form-submit-bg,
.floating-label-form-group .form-submit-bg {
	position: absolute;
	top: 0;
	right: 0;
	background: #FFF;
	width: 80px;
	height: 100%;
	padding: 0;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	outline: 0
}

.form-group-sm .form-submit,
.floating-label-form-group .form-submit {
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 100%;
	padding: 0;
	z-index: 3
}

.form-group-sm .form-submit:hover~.icon-form,
.floating-label-form-group .form-submit:hover~.icon-form {
	color: #2ECC72;
	transition: all .2s ease-in-out
}

.floating-label-form-group-with-value label {
	top: 8px;
	opacity: 1
}

.floating-label-form-group-with-value input {
	top: 6px
}

.form-group.floating-label-form-group-with-value label {
	top: 3px
}

.floating-label-form-group-with-focus label {
	color: #13a969
}

form .row:first-child .floating-label-form-group {
	border-top: 1px solid #ddd
}

.icon-form {
	top: 8px;
	right: 28px;
	font-size: 20px;
	font-weight: 700
}

.floating-label-form-group.form-group-sm .icon-form {
	top: 8px;
	right: 28px;
	font-size: 22px;
	font-weight: 700
}

.floating-label-form-group.form-group .icon-form {
	top: 12px;
	right: 26px;
	font-size: 24px;
	font-weight: 700
}

.btn {
	font-weight: normal;
	font-size: 16px;
	line-height: 48px;
	color: #565657;
	padding: 0 50px;
	overflow: hidden;
	border: 1px solid #ddd;
	position: relative;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	border-radius: 6px;
}

.btn-yellow {
	background-color: #f1c40f;
	border: none;
	line-height: 48px;
	text-align: left;
	color: #ffffff !important;
	z-index: 1;
	text-align: center;
	position: relative;
	text-transform: uppercase;
}

.btn-yellow:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: -17px;
	width: 12px;
	transform: skew(-14deg);
	background-color: #f39c12;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
	z-index: -1;
}

.btn-yellow:hover {
	background-color: #e67e22;
	transition: background-color 0.1s ease-in;
	transition-delay: 0.05s;
	color: #ffffff;
	text-decoration: none;
}

.btn-yellow:hover:before,
.btn-yellow:focus:before {
	width: 100%;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
}

.btn-green {
	background-color: #2ECC72;
	border: none;
	line-height: 48px;
	text-align: left;
	color: #ffffff !important;
	text-transform: uppercase;
}

.btn-green:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: -17px;
	width: 12px;
	transform: skew(-14deg);
	background-color: #13a969;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
	z-index: 0;
}

.btn-green:hover {
	background-color: #199762;
	transition: background-color 0.1s ease-in;
	transition-delay: 0.05s;
	color: #ffffff;
	text-decoration: none;
}

.btn-green:hover:before,
.btn-green:focus:before {
	width: 100%;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
}

.btn-green .inner-btn {
	z-index: 1;
	position: relative;
	display: block;
	text-align: center;
}

.btn-white {
	background-color: #ffffff;
	line-height: 48px;
	text-align: left;
	z-index: 1;
	text-align: center;
	position: relative;
	text-transform: uppercase;
}

.btn-white:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: -17px;
	width: 12px;
	transform: skew(-14deg);
	background-color: #ececec;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
	z-index: -1;
}

.btn-white:hover {
	background-color: #dcdcdc;
	transition: background-color 0.1s ease-in;
	transition-delay: 0.05s;
	text-decoration: none;
}

.btn-white:hover:before,
.btn-white:focus:before {
	width: 100%;
	transition: all 0.1s ease-in;
	-webkit-transition: all 0.1s ease-in;
}

.close,
dt {
	font-weight: 700
}

@media (max-width:767px) {
	.btn {
		padding-left: 3px;
		padding-right: 3px;
		width: 75%
	}
}

.btn-block,
input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
	width: 100%
}

@media (min-width:768px) and (max-width:991px) {
	.btn {
		padding-left: 35px;
		padding-right: 35px
	}
	.btn.btn-icon-cta:after {
		font-size: 14px
	}
}

@media (max-width:599px) {
	.btn {
		width: 100%
	}
}

.btn-block {
	display: block
}

.flx:before {
	display: none!important
}

.flx-dir-row {
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row
}

.flx-dir-row-rev {
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse
}

.flx-dir-col {
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column
}

.flx-dir-col-rev {
	-webkit-box-direction: reverse;
	-webkit-box-orient: vertical;
	-webkit-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse
}

.flx-nowrap {
	-webkit-flex-wrap: nowrap;
	-moz-flex-wrap: nowrap;
	-ms-flex-wrap: none;
	flex-wrap: nowrap
}

.flx-wrap {
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.flx-wrap-rev {
	-webkit-flex-wrap: wrap-reverse;
	-moz-flex-wrap: wrap-reverse;
	-ms-flex-wrap: wrap-reverse;
	flex-wrap: wrap-reverse
}

.flx-yt {
	-webkit-box-align: start;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	-moz-align-items: flex-start;
	align-items: flex-start
}

.flx-yc {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center
}

.flx-yb {
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	-moz-align-items: flex-end;
	align-items: flex-end
}

.flx-ys {
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	-webkit-align-items: stretch;
	-moz-align-items: stretch;
	align-items: stretch
}

.flx-as-yt {
	-webkit-align-self: flex-start;
	-moz-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start
}

.flx-as-yb {
	-webkit-align-self: flex-end;
	-moz-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end
}

.flx-as-yc {
	-webkit-align-self: center;
	-moz-align-self: center;
	-ms-flex-item-align: center;
	align-self: center
}

.flx-as-ys {
	-webkit-align-self: stretch;
	-moz-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch
}

.flx-as-ybs {
	-webkit-align-self: baseline;
	-moz-align-self: baseline;
	-ms-flex-item-align: baseline;
	align-self: baseline
}

.flx-jc-xl {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	justify-content: flex-start
}

.flx-jc-xc {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	justify-content: center
}

.flx-jc-xr {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	justify-content: flex-end
}

.flx-jc-xa {
	-ms-flex-pack: distribute;
	-webkit-justify-content: space-around;
	-moz-justify-content: space-around;
	justify-content: space-around
}

.flx-jc-xb {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	justify-content: space-between
}

dl,
ol,
ul {
	margin-top: 0
}

@media screen and (min-width:768px) {
	.button-text-group {
		width: auto
	}
	.button-text-group sup {
		width: auto;
		display: inline
	}
}

ol,
ul {
	margin-bottom: 10px;
	padding-left: 25px
}

dl {
	margin-bottom: 19px
}

dd,
dt {
	line-height: 1.5
}

dd {
	margin-left: 0
}

.dl-horizontal dd:after,
.dl-horizontal dd:before {
	content: " ";
	display: table
}

@media (min-width:768px) {
	.dl-horizontal dt {
		float: left;
		width: 160px;
		clear: left;
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}
	.dl-horizontal dd {
		margin-left: 180px
	}
}

.clearfix:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after {
	clear: both
}

.block {
	display: block!important
}

.fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear .2s ease-in-out;
	-moz-transition: opacity .15s linear .2s ease-in-out;
	-ms-transition: opacity .15s linear .2s ease-in-out;
	-o-transition: opacity .15s linear .2s ease-in-out;
	transition: opacity .15s linear .2s ease-in-out
}

.fade.in {
	opacity: 1
}

.collapse {
	display: none
}

.collapse.in {
	display: block
}

tr.collapse.in {
	display: table-row
}

tbody.collapse.in {
	display: table-row-group
}

.collapsing {
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	transition-property: height, visibility;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent
}

.dropdown-toggle:focus {
	outline: 0
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	float: left;
	width: 100%;
	padding: 5px 0;
	max-height: 250px;
	overflow-y: scroll;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #fff;
	border-radius: 0 0 6px 6px;
	background-clip: padding-box;
	border: 1px solid #ddd;
}

.dropdown-menu-right,
.dropdown-menu.pull-right {
	left: auto;
	right: 0
}

.dropdown-header,
.dropdown-menu>li>a {
	display: block;
	padding: 3px 20px;
	white-space: nowrap;
	line-height: 1.5
}

.dropdown-menu .divider {
	height: 1px;
	margin: 8.5px 0;
	overflow: hidden;
	background-color: #ddd
}

.dropdown-menu>li:hover {
	background-color: #ececec;
}

.dropdown-menu>li>a {
	clear: both;
	font-weight: 400;
	color: #242423
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
	text-decoration: none;
	color: #565657;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
	text-decoration: none;
	outline: 0;
}

.dropdown-header,
.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover,
.nav>li.disabled>a {
	color: #999
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	cursor: not-allowed
}

.open>.dropdown-menu {
	display: block
}

.open>a {
	outline: 0
}

.dropdown-menu-left {
	left: 0;
	right: auto
}

.dropdown-header {
	font-size: 12px
}

.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990
}

.pull-right>.dropdown-menu {
	right: 0;
	left: auto
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid\9;
	content: ""
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 2px
}

.modal,
.modal-backdrop {
	bottom: 0;
	right: 0;
	top: 0;
	left: 0
}

@media (min-width:7992px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto
	}
	.navbar-right .dropdown-menu-left {
		left: 0;
		right: auto
	}
}

.nav {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none
}

.nav:after,
.nav:before {
	content: " ";
	display: table
}

.nav>li,
.nav>li>a {
	display: block;
	position: relative
}

.nav>li>a {
	padding: 10px 15px
}

.nav>li>a:focus,
.nav>li>a:hover {
	text-decoration: none;
	background-color: #f8f8f8
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
	color: #f4f4f4;
	text-decoration: none;
	background-color: transparent;
	cursor: not-allowed
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
	background-color: #f8f8f8;
	border-color: #406EB3
}

.nav .nav-divider {
	height: 1px;
	margin: 8.5px 0;
	overflow: hidden;
	background-color: #e5e5e5
}

.nav>li>a>img {
	max-width: none
}

.nav-tabs {
	border-bottom: 1px solid #ddd
}

.nav-tabs>li {
	float: left;
	margin-bottom: -1px
}

.nav-tabs>li>a {
	margin-right: 2px;
	line-height: 1.5;
	border: 1px solid transparent;
	border-radius: 6px 6px 0 0
}

.nav-tabs>li>a:hover {
	border-color: #f8f8f8 #f8f8f8 #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
	color: #ddd;
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	border-bottom-color: transparent;
	cursor: default
}

.nav-pills>li {
	float: left
}

.nav-justified>li,
.nav-stacked>li,
.nav-tabs.nav-justified>li {
	float: none
}

.nav-pills>li>a {
	border-radius: 6px
}

.nav-pills>li+li {
	margin-left: 2px
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
	color: #fff;
	background-color: #26478D
}

.nav-stacked>li+li {
	margin-top: 2px;
	margin-left: 0
}

.nav-justified,
.nav-tabs.nav-justified {
	width: 100%
}

.nav-justified>li>a,
.nav-tabs.nav-justified>li>a {
	text-align: center;
	margin-bottom: 5px
}

.nav-justified>.dropdown .dropdown-menu {
	top: auto;
	left: auto
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
	border-bottom: 0
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
	margin-right: 0;
	border-radius: 6px
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover {
	border: 1px solid #ddd
}

@media (min-width:992px) {
	.nav-justified>li,
	.nav-tabs.nav-justified>li {
		display: table-cell;
		width: 1%
	}
	.nav-justified>li>a,
	.nav-tabs.nav-justified>li>a {
		margin-bottom: 0
	}
	.nav-tabs-justified>li>a,
	.nav-tabs.nav-justified>li>a {
		border-bottom: 1px solid #ddd;
		border-radius: 6px 6px 0 0
	}
	.nav-tabs-justified>.active>a,
	.nav-tabs-justified>.active>a:focus,
	.nav-tabs-justified>.active>a:hover,
	.nav-tabs.nav-justified>.active>a,
	.nav-tabs.nav-justified>.active>a:focus,
	.nav-tabs.nav-justified>.active>a:hover {
		border-bottom-color: #f8f8f8
	}
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before {
	content: " ";
	display: table
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: 0;
	border: 1px solid transparent
}

.navbar-collapse {
	overflow-x: visible;
	padding-right: 15px;
	padding-left: 15px;
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
	-webkit-overflow-scrolling: touch
}

.navbar-collapse.in {
	overflow-y: auto
}

@media (min-width:992px) {
	.navbar-header {
		float: left
	}
	.navbar-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none
	}
	.navbar-collapse.collapse {
		display: block!important;
		height: auto!important;
		padding-bottom: 0;
		overflow: visible!important
	}
	.navbar-collapse.in {
		overflow-y: visible
	}
	.navbar-fixed-bottom .navbar-collapse,
	.navbar-fixed-top .navbar-collapse,
	.navbar-static-top .navbar-collapse {
		padding-left: 0;
		padding-right: 0
	}
}

.modal,
.modal-open {
	overflow: hidden
}

@media (max-device-width:480px) and (orientation:landscape) {
	.navbar-fixed-bottom .navbar-collapse,
	.navbar-fixed-top .navbar-collapse {
		max-height: 200px
	}
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
	margin-right: 0px;
	margin-left: 0px
}

.navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px
}

.navbar-fixed-bottom,
.navbar-fixed-top {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030
}

.navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px
}

.navbar-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0
}

.navbar-brand {
	float: left;
	padding: 15.5px 15px;
	font-size: 20px;
	line-height: 19px;
	height: 50px
}

.navbar-brand:focus,
.navbar-brand:hover {
	text-decoration: none
}

.navbar-brand>img {
	display: block
}

@media (min-width:9928px) {
	.container-fluid>.navbar-collapse,
	.container-fluid>.navbar-header,
	.container>.navbar-collapse,
	.container>.navbar-header {
		margin-right: 0;
		margin-left: 0
	}
	.navbar-fixed-bottom,
	.navbar-fixed-top,
	.navbar-static-top {
		border-radius: 0
	}
}

.navbar>.container .navbar-brand {
	margin-left: 50px
}

.navbar-toggle {
	position: relative;
	float: right;
	margin-right: 15px;
	padding: 9px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 6px
}

.navbar-toggle:focus {
	outline: 0
}

.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
	margin-top: 4px
}

.navbar-nav {
	margin: 7.75px -15px
}

.navbar-nav>li>a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 19px
}

@media (max-width:991px) {
	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		box-shadow: none
	}
	.navbar-nav .open .dropdown-menu .dropdown-header,
	.navbar-nav .open .dropdown-menu>li>a {
		padding: 5px 15px 5px 25px
	}
	.navbar-nav .open .dropdown-menu>li>a {
		line-height: 19px
	}
	.navbar-nav .open .dropdown-menu>li>a:focus,
	.navbar-nav .open .dropdown-menu>li>a:hover {
		background-image: none
	}
}

@media (min-width:992px) {
	.navbar-toggle {
		display: none
	}
	.navbar-nav {
		float: left;
		margin: 0
	}
	.navbar-nav>li {
		float: left
	}
	.navbar-nav>li>a {
		padding-top: 15.5px;
		padding-bottom: 15.5px
	}
}

.navbar-form {
	padding: 10px 15px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
	margin: 0 -15px
}

@media (min-width:992px) {
	.navbar-form .form-control-static,
	.navbar-form .form-group {
		display: inline-block
	}
	.navbar-form .control-label,
	.navbar-form .form-group {
		margin-bottom: 0;
		vertical-align: middle
	}
	.navbar-form .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.navbar-form .input-group {
		display: inline-table;
		vertical-align: middle
	}
	.navbar-form .input-group .form-control,
	.navbar-form .input-group .input-group-addon,
	.navbar-form .input-group .input-group-btn {
		width: auto
	}
	.navbar-form .input-group>.form-control {
		width: 100%
	}
	.navbar-form .checkbox,
	.navbar-form .radio {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle
	}
	.navbar-form .checkbox label,
	.navbar-form .radio label {
		padding-left: 0
	}
	.navbar-form .checkbox input[type=checkbox],
	.navbar-form .radio input[type=radio] {
		position: relative;
		margin-left: 0
	}
	.navbar-form .has-feedback .form-control-feedback {
		top: 0
	}
	.navbar-form {
		width: auto;
		border: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-shadow: none;
		box-shadow: none
	}
}

@media (max-width:991px) {
	.navbar-form .form-group {
		margin-bottom: 5px
	}
	.navbar-form .form-group:last-child {
		margin-bottom: 0
	}
}

.navbar-nav>li>.dropdown-menu {
	margin-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
	margin-bottom: 0;
	border-radius: 6px 6px 0 0
}

.navbar-btn {
	margin-top: 0;
	margin-bottom: 0
}

.navbar-btn.btn-sm {
	margin-top: 5px;
	margin-bottom: 5px
}

.navbar-btn.btn-xs {
	margin-top: 14px;
	margin-bottom: 14px
}

.navbar-text {
	margin-top: 15.5px;
	margin-bottom: 15.5px
}

@media (min-width:991px) {
	.navbar-text {
		float: left;
		margin-left: 15px;
		margin-right: 15px
	}
	.navbar-left {
		float: left!important
	}
	.navbar-right {
		float: right!important;
		margin-right: -15px
	}
	.navbar-right~.navbar-right {
		margin-right: 0
	}
}

.navbar-default {
	background-color: #ffffff;
}

.navbar-default .navbar-brand {
	color: #565657
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
	color: #5e5e5e;
	background-color: transparent
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-text {
	color: #777
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
	color: #333;
	background-color: transparent
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
	color: #555;
	border-radius: 4px;
	background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
	color: #ccc;
	background-color: transparent
}

.navbar-default .navbar-toggle {
	border-color: #ddd
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
	background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #888
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e7e7e7
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
	background-color: #e7e7e7;
	color: #555
}

@media (max-width:767px) {
	.navbar-default .navbar-nav .open .dropdown-menu>li>a {
		color: #777
	}
	.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
	.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
		color: #333;
		background-color: transparent
	}
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a,
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
	.navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
		color: #555;
		background-color: #e7e7e7
	}
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
	.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
		color: #ccc;
		background-color: transparent
	}
}

.navbar-default .navbar-link {
	color: #777
}

.navbar-default .navbar-link:hover {
	color: #333
}

.navbar-default .btn-link {
	color: #777
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
	color: #333
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
	color: #ccc
}

.navbar-inverse {
	background-color: #222;
	border-color: #090909
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
	background-color: transparent;
	color: #fff
}

.navbar-inverse .navbar-brand {
	color: #fff
}

.navbar-inverse .navbar-nav>li>a,
.navbar-inverse .navbar-text {
	color: #fff
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
	color: #fff;
	background-color: #090909
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
	color: #444;
	background-color: transparent
}

.navbar-inverse .navbar-toggle {
	border-color: #333
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
	background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
	background-color: #fff
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
	border-color: #101010
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
	background-color: #090909;
	color: #fff
}

@media (max-width:767px) {
	.navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
		border-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
		color: #fff
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
	.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
		color: #fff;
		background-color: transparent
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
		color: #fff;
		background-color: #090909
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
	.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
		color: #444;
		background-color: transparent
	}
}

.navbar-inverse .btn-link,
.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover,
.navbar-inverse .navbar-link,
.navbar-inverse .navbar-link:hover {
	color: #fff
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
	color: #444
}

.thumbnail {
	display: block;
	width: 100%;
	padding: 0 25px 10px;
	margin-bottom: 19px;
	line-height: 1.5;
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	border-radius: 6px;
	-webkit-transition: border .2s ease-in-out .2s ease-in-out;
	-moz-transition: border .2s ease-in-out .2s ease-in-out;
	-ms-transition: border .2s ease-in-out .2s ease-in-out;
	-o-transition: border .2s ease-in-out .2s ease-in-out;
	transition: border .2s ease-in-out .2s ease-in-out
}

.thumbnail a>img,
.thumbnail>img {
	display: block;
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	margin-top: -30px;
	position: relative;
	border-radius: 6px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, .2)
}

.thumbnail .caption {
	padding: 0 20px;
	color: #575756
}

.thumbnail .caption h3,
.thumbnail .caption h4 {
	color: #26478D
}

@media (max-width:767px) {
	.thumbnail {
		padding: 0 15px 10px
	}
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
	border-color: #406EB3
}

.close {
	float: right;
	font-size: 24px;
	line-height: 1;
	color: #575756;
	text-shadow: 0 1px 0 #fff
}

.popover,
.tooltip {
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-break: auto;
	text-decoration: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal
}

.modal-title,
.popover,
.tooltip {
	line-height: 1.5
}

.popover,
.text-hide,
.tooltip {
	text-shadow: none
}

.close:focus,
.close:hover {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	opacity: .5;
	filter: alpha(opacity=50)
}

button.close {
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	-webkit-appearance: none
}

.modal-content,
.popover {
	background-clip: padding-box
}

.modal {
	display: none;
	position: fixed;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0
}

.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before {
	display: table;
	content: " "
}

.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform .3s ease-out;
	-moz-transition: -moz-transform .3s ease-out;
	-o-transition: -o-transform .3s ease-out;
	transition: transform .3s ease-out
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0)
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 30px 15px
}

.modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
	outline: 0
}

.modal-backdrop {
	position: fixed;
	z-index: 1040;
	background-color: #000
}

.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0)
}

.modal-backdrop.in {
	opacity: .5;
	filter: alpha(opacity=50)
}

.modal-header {
	padding: 20px 30px;
	border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
	margin-top: 6px
}

.modal-title {
	margin: 0
}

.modal-body {
	position: relative;
	padding: 40px 30px
}

@media (max-width:767px) {
  .modal-body {
	padding: 20px 25px 30px
}
  
  
}
.modal-footer {
	padding: 15px 30px;
	text-align: right;
	border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
	margin-left: 5px;
	margin-bottom: 0
}

.modal-footer .btn-group .btn+.btn {
	margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
	margin-left: 0
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll
}

@media (min-width:768px) {
	.modal-dialog {
		width: 737px;
		margin: 30px auto
	}
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
	}
	.modal-sm {
		width: 300px
	}
	.modal-header .close {
		margin-top: 3px
	}
}

@media (min-width:992px) {
	.modal-lg {
		width: 900px
	}
}

@media (min-width:320px) {
	.modal-header .close {
		margin-top: 0
	}
}

.visible-xl,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
	display: none!important
}

@media (min-width:1440px) {
	.container {
		width: 1440px
	}
	.visible-lg {
		display: none!important
	}
	.visible-xl {
		display: block!important
	}
	table.visible-xl {
		display: table!important
	}
	tr.visible-xl {
		display: table-row!important
	}
	td.visible-xl,
	th.visible-xl {
		display: table-cell!important
	}
	.visible-xl-block {
		display: block!important
	}
	.visible-xl-inline {
		display: inline!important
	}
	.visible-xl-inline-block {
		display: inline-block!important
	}
}

@media (min-width:1200px) and (max-width:1439px) {
	.hidden-lg {
		display: none!important
	}
}

@media (min-width:1440px) {
	.hidden-xl {
		display: none!important
	}
	.hidden-lg {
		display: block!important
	}
	table.hidden-lg {
		display: table!important
	}
	tr.hidden-lg {
		display: table-row!important
	}
	td.hidden-lg,
	th.hidden-lg {
		display: table-cell!important
	}
	.visible-lg-block,
	.visible-lg-inline,
	.visible-lg-inline-block {
		display: none!important
	}
}

.clearfix:after,
.clearfix:before {
	content: " ";
	display: table
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.pull-right {
	float: right!important
}

.pull-left {
	float: left!important
}

.center-col {
	float: none;
	margin-left: auto!important;
	margin-right: auto!important
}

.no-mg,
.no-pd-mg {
	margin: 0!important
}

.fw {
	width: 100%
}

.hide {
	display: none!important
}

.show {
	display: block!important
}

.hidden,
.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
	display: none!important
}

.invisible {
	visibility: hidden
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	background-color: transparent;
	border: 0
}

.affix {
	position: fixed
}

@-ms-viewport {
	width: device-width
}

@media (max-width:767px) {
	.visible-xs {
		display: block!important
	}
	table.visible-xs {
		display: table!important
	}
	tr.visible-xs {
		display: table-row!important
	}
	td.visible-xs,
	th.visible-xs {
		display: table-cell!important
	}
	.visible-xs-block {
		display: block!important
	}
	.visible-xs-inline {
		display: inline!important
	}
	.visible-xs-inline-block {
		display: inline-block!important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.visible-sm {
		display: block!important
	}
	table.visible-sm {
		display: table!important
	}
	tr.visible-sm {
		display: table-row!important
	}
	td.visible-sm,
	th.visible-sm {
		display: table-cell!important
	}
	.visible-sm-block {
		display: block!important
	}
	.visible-sm-inline {
		display: inline!important
	}
	.visible-sm-inline-block {
		display: inline-block!important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.visible-md {
		display: block!important
	}
	table.visible-md {
		display: table!important
	}
	tr.visible-md {
		display: table-row!important
	}
	td.visible-md,
	th.visible-md {
		display: table-cell!important
	}
	.visible-md-block {
		display: block!important
	}
	.visible-md-inline {
		display: inline!important
	}
	.visible-md-inline-block {
		display: inline-block!important
	}
}

@media (min-width:1200px) {
	.visible-lg {
		display: block!important
	}
	table.visible-lg {
		display: table!important
	}
	tr.visible-lg {
		display: table-row!important
	}
	td.visible-lg,
	th.visible-lg {
		display: table-cell!important
	}
	.visible-lg-block {
		display: block!important
	}
	.visible-lg-inline {
		display: inline!important
	}
	.visible-lg-inline-block {
		display: inline-block!important
	}
	.hidden-lg {
		display: none!important
	}
}

@media (max-width:767px) {
	.hidden-xs {
		display: none!important
	}
}

@media (min-width:768px) and (max-width:991px) {
	.hidden-sm {
		display: none!important
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.hidden-md {
		display: none!important
	}
}

.alert {
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 6px;
	text-align: center;
	color: #fff;
}

.alert-success {
	background-color: #57BC60;
}

.alert-danger {
	background-color: #D75552;
}

.alert-warning {
	background-color: #EDAD54;
}

.alert-info {
	background-color: #3879B3;
}

.notification-box {
	text-align: center;
	padding: 15px 0
}

body {
	background: #faf4e1;
	background: -webkit-gradient(linear, left top, right bottom, color-stop(25%, #ffffff), to(#faf4e1));
	background: linear-gradient(to bottom right, #ffffff 25%, #faf4e1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#faf4e1', GradientType=0);
	min-height: 100vh
}

section,
main {
	width: 100%
}

#nav .container {
	padding-top: 20px;
	padding-bottom: 20px
}

#nav.navbar-top .container {
	padding-bottom: 0
}

#nav {
	border: none
}

#nav .logo {
	background: url(../images/tent-logo.svg) no-repeat;
	width: 250px;
	height: 50px;
	background-size: contain
}

#nav .nav-logo img {
	max-height: 50px;
	width: auto
}

#nav .nav-logo .title {
	padding-left: 10px;
	color: #565657;
}

#nav .nav-logo .title span {
	padding-left: 10px;
}

#nav .nav-logo .title:hover {
	color: #666
}

.btn:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.text-center {
	text-align: center;
}

#footer {
	text-align: center;
	
	color: #fff;
	margin-top:40px;
	height: 300px;
	align-self: flex-end;
	align-self: -webkit-flex-end;
	display:flex;
	display:-webkit-flex;
	position:relative;
	height: 300px;
}

#footer:before {
	position:absolute;
	content:'';
	top:0;
	left:0;
	background: url('../images/mountain.svg');
	color: #fff;
	height:200px;
	width:100%;
}

#footer .container {
	padding-top: 40px;
	padding-bottom: 40px;
	
	
}
#footer .footer-bg {
	    width: 100%;
	background:#01281B;
		align-self: flex-end;
	align-self: -webkit-flex-end;
	
}
.heading-wrapper {
	margin-bottom: 30px
}

@media (max-width:768px) {
	.heading-wrapper {
		margin-bottom: 20px
	}
	#footer:before {
   
    background-size: contain;
    background-position: bottom center;
    height: 121px;
	}
	#footer {
		margin-top:20px;
		height:220px
	}
}


/*helper*/

.hide {
	display: none
}

.button-center {
	text-align: center
}

.wrong-email.form-group-sm {
	border: 1px solid #cb123e
}

.wrong-email.form-group-sm label {
	color: #cb123e
}

.mt-20 {
	margin-top: 20px
}

@media (max-width:768px) {
	.heading-wrapper {
		margin-bottom: 20px
	}
}

@media (max-width:991px) and (min-width:768px) {
	.mb-sm-20 {
		margin-bottom: 20px
	}
}

@-webkit-keyframes autofill {
	to {
		color: #666;
		background: transparent !important;
	}
}

input:-webkit-autofill {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 99999s;
}

.info-icon-wrap {
	position: relative;
	display: inline-block;
	left: 2px;
    top: 1px;
}

.tooltip-bottom {
	position: absolute;
	z-index: 3;
	border: 1px solid #fff;
	background: #fff;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-51%);
	padding: 20px 25px;
	width: 300px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	top: 40px;
	line-height: 1.5;
	border-radius: 6px;
	margin-left: 2px;
	font-size: 14px
}

.tooltip__arrow {
	width: 50px;
	height: 25px;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
}

.tooltip__arrow:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	background: white;
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
	top: 100%;
	left: 50%;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.tooltip-wrap {
	color: #575756;
	font-weight: normal;
}

.warning-color {
	color: #E54D42
}