
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z6n4fc');
  src:  url('fonts/icomoon.eot?z6n4fc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?z6n4fc') format('woff2'),
    url('fonts/icomoon.ttf?z6n4fc') format('truetype'),
    url('fonts/icomoon.woff?z6n4fc') format('woff'),
    url('fonts/icomoon.svg?z6n4fc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-triangle-left:before {
  content: "\e914";
}
.icon-right-triangle-arrow:before {
  content: "\e915";
}
.icon-info:before {
  content: "\e913";
}
.icon-shopping-cart:before {
  content: "\e912";
}
.icon-resume:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e911";
}
.icon-register:before {
  content: "\e90f";
}
.icon-calendar:before {
  content: "\e90e";
}
.icon-right-arrow:before {
  content: "\e90d";
}
.icon-black-envelope:before {
  content: "\e908";
}
.icon-lock:before {
  content: "\e909";
}
.icon-padlock:before {
  content: "\e90a";
}
.icon-phone:before {
  content: "\e90b";
}
.icon-user:before {
  content: "\e90c";
}
.icon-down-arrow-outline:before {
  content: "\e900";
}
.icon-down-arrow:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-select:before {
  content: "\e903";
}
.icon-camp:before {
  content: "\e904";
}
.icon-notification:before {
  content: "\e905";
}
.icon-monitoring:before {
  content: "\e906";
}
.icon-filter:before {
  content: "\e907";
}
.icon-address-book:before {
  content: "\e944";
}
