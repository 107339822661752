/*form*/

#tracking .form-group-sm.floating-label-form-group-with-value input {
	top: 0!important
}

#tracking .form-group-sm .form-control {
	font-size: 14px;
}

#tracking .floating-label-form-group {
	margin: 0 0 20px 0;
}

#tracking label {
	margin: 5px 0
}


/*tracking form*/

#tracking .radio+.radio {
	margin-top: 10px;
}

#tracking .filter-wrap .radio {
	width: 170px
}

#tracking .check-in-wrap .check-in-label{
	flex-shrink: 0;
	width:auto;
	padding-right: 40px;
}

#tracking .check-in-start .form-group-sm, #tracking .check-in-end .form-group-sm{
	margin-top:0;
}
#tracking .ul.dropdown-list li.active {
	background: #f4f4f4
}

#tracking .campsites-filter.selected {
	padding: 20px 0
}


#tracking .dropdown-toggle {
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 8px 15px;
	color: #565657;
	width: 100%;
	background: #fff;
}

.dropdown {
	position: relative
}

#tracking .tracking-wrap {
	margin-bottom: 30px;
	margin-top: 20px
}

#tracking hr {
	width: 70%;
	margin: 0 auto 40px;
}
#tracking .btn-group{
	position:relative
}
@media (max-width:768px) {
	#tracking .dropdown-toggle,
	#tracking .form-wrap>div,
	#tracking h6 {
		margin-right: auto;
		margin-left: auto;
	}
	#tracking .tracking-wrap {
		margin-bottom: 20px;
	}
	#tracking .dropdown{
		margin-bottom:20px
	}
	#tracking .campsites-filter.selected {
	padding: 20px 0 0 0
}
	
}

@media (max-width:500px) {
	#tracking .dropdown-toggle,
	#tracking .form-wrap>div,
	#tracking h6 {
		width: 100%;
	}
}


#list .list-wrapper{
	margin-bottom:30px
}
#list .list-wrapper >div{
	width:100%
}
#list .list-title{
	background:#2ECC72;
	border-radius:4px;
	color:#fff
}
#list .list-title >div{
	padding:15px 10px
}
#list .list-title >div:not(:last-child){
	border-right:1px solid #fff
}

#list .list-description{
	border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
	background: #fff;
}
#list .list-description >div{
	padding:20px 10px;
	word-break: break-word;
}
#list .col1{
	width:33%;
	flex-shrink: 0;
}
#list .col2{
	width:20%;
	flex-shrink: 0;
}
#list .col3{
	width:27%;
	flex-shrink: 0;
}
#list .col4{
	width:20%;
	flex-shrink: 0;
}

#list .campground {
	align-items:flex-start;
	-webkit-align-items:flex-start;
}
#list .list-content .campground img{
	width:160px;
	height:auto;
	border-radius:4px;
	padding-bottom:10px
}
#list .list-content .campground .camp-title{
	padding-left:15px
}
#list .list-content .list-icon{
    position: relative;
    top: 1px;
    left: 4px;
	font-size: 90%;
}
#list .list-content .btn{
	margin-top:15px
}
#list .list-content .guarantee{
    padding: 3px 11px;
    display: inline-block;
    margin-left: 10px;
    color: #e67e22;
    border: 1px solid #e67e22;
    border-radius: 14px;

}

#list .filter-line{
	position:relative
}
#list .filter-line .info-icon-wrap{
	position:static
}
#list .filter-line .tooltip-bottom{
	top:130%
}
.info-icon-wrap-filter {
	cursor:pointer
}
@media (min-width:768px) {
	#list .list-title-mobile{
		display:none
	}
	#list .col4 .list-content .btn{
		padding-left:0;
		padding-right:0;
		width:100%;
		text-align: center;
	}
	#list .warning-message{
		font-size:14px
	}
	
}
@media (max-width:1439px)  and (min-width:992px){
	
	#list .list-content .campground img {
		width: 130px;
	}
}
@media (max-width:991px) {
	#list .campground {
		flex-wrap:wrap;
		-webkit-flex-wrap:wrap;
	}
	#list .list-content .campground img {
		width: 90%;
	}
	#list .list-content .campground .camp-title{
		padding-left:0;
		
		width:100%
	}
	#list .col1{
		width:27%;
		flex-shrink: 0;
	}
	
	#list .col2{
		width:25%;
		flex-shrink: 0;
	}
	#list .col3{
		width:28%;
		flex-shrink: 0;
	}
	#list .col4{
		width:20%;
		flex-shrink: 0;
	}
}
@media (max-width:767px) {
	#list .list-wrapper >div {
		padding: 10px 0;
	}
	#list .list-title{
		display:none
	}
	#list .list-description >div{
		width:100%;
		display:flex;
		display:-webkit-flex;
		padding: 10px 10px;
	}
	#list .list-description{
		flex-wrap:wrap;
		-webkit-flex-wrap:wrap;
	}
	#list .list-content .campground img {
		height:120px;
		width:auto;
	}
	#list .list-title-mobile{
		width:25%;
		font-weight:500;
		padding-right: 10px;
	}
	#list .list-content{
		width:75%;
		padding-left:10px
	}

}
@media (max-width:545px) {
	#list  .info-icon-wrap{
		position:static
	}
	#list  .warning-color{
		position:relative
	}
	#list .tooltip-bottom{
		width:280px;
		margin-left:0
	}
}
@media (max-width:455px) {
	#list .list-title-mobile{
		width:39%;

	}
	#list .list-content{
		width:61%;
		padding-left:0
	}
	#list .tooltip-bottom{
		width:270px;
		margin-left:-50px
	}
}
@media (max-width:360px) {
	
	#list .tooltip-bottom{
		width:260px;
		margin-left:30px;
		top:48px;
	}
}
@media (max-width:330px) {
	
	#list .tooltip-bottom{
		width:250px;
		margin-left:0;
		top:50px;
	}
}
