#hero .hero-bg {
	background-image: url(../images/bg.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;

}

#hero .heading-content-bg {
	z-index: 1;
	background-color: rgba(43, 40, 11, 0.6);
	padding: 90px 50px;
	border-radius: 6px;
}

#hero .container {
	color: #fff;
	text-align: center;
	position: relative
}

#hero .links-wrap {
	position: absolute;
	top: 15px;
	right: 0;
}

#hero .links-wrap a {
	color: #fff;
	padding-right: 30px
}

#hero h4 {
	line-height: 35px
}

#messages {
	padding: 20px 0px;
}

@media (min-width:1200px) {
	#hero .container {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	#hero .heading-wrapper {
		margin-bottom: 50px
	}
	#hero .links-wrap {
		font-size:18px
	}
}

@media (max-width:1199px) {
	#hero .container {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	#hero .heading-content-bg {
		padding: 50px 40px;
	}
}

@media (max-width:991px) {
	#hero .heading-content-bg {
		padding: 60px 40px;
	}
}

@media (max-width:767px) {
	#hero .hero-bg {
		background-image: url(../images/bg-mb.jpg);
	}
	#hero .container {
		padding-top: 60px;
		padding-bottom: 40px;
	}
	#hero h4 {
		line-height: 28px
	}
	#hero .heading-content-bg {
		padding: 30px 20px;
	}
}

#hero .btn-wrap>a.btn:first-child {
	margin-right: 20px
}

@media (max-width:767px) {
	#hero .btn-wrap>a.btn:first-child {
		margin-right: 0;
		margin-bottom: 20px
	}
}

#login .forgot-pass {
	display: inline-block;
	padding: 20px 0 0 0
}

#profile #personal-email {
	width: 100%
}

#profile .icon-form {
	color: #2ECC72
}

#how img {
	height: auto;
	max-width: 100%;
}

#how .how-wrap {
	margin: 25px 0
}

@media (min-width:1200px) {
	#how .container {
		padding-top: 140px;
		padding-bottom: 140px;
	}
	#how img {
		height: auto;
		max-width: 90%;
	}
	#how .how-wrap {
		margin: 35px 0
	}
}

#how .how-wrap span {
	display: block
}

#how .how-wrap>span:first-child {
	font-size: 30px;
	font-weight: 500;
	padding: 0 10px 0 0;
	color: #EEC857;
}

@media (max-width:767px) {
	#how img {
		height: 160px;
		width: auto;
		margin: 0 auto 30px;
		display: block;
	}
	#how h2 {
		text-align: center
	}
	#how .how-wrap {
		margin: 20px 0
	}
	#how .btn-wrap a {
		margin: 35px auto 0;
		display: block
	}
}


@media (max-width:768px) {
	.pricing-grids.flx {
		flex-wrap: wrap
	}
	.pricing-grids .pricing-grid2 {
		order: 1
	}
	.pricing-grids .pricing-grid1 {
		order: 3
	}
	.pricing-grids .pricing-grid3 {
		order: 2
	}
}

.pricing-grid1,
.pricing-grid2,
.pricing-grid3 {
	width: 31.5%;
	text-align: center;
	margin-right: 2%;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	padding: 0;
	border: none;
	border-radius: 0.7em;
	-webkit-border-radius: 0.7em;
	-o-border-radius: 0.7em;
	-moz-border-radius: 0.7em;
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .2)
}

.pricing-grid3 {
	margin-right: 0;
}

.pricing-grid2 {}

.pricing-grid1:hover,
.pricing-grid2:hover,
.pricing-grid3:hover {
	transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	z-index: 1;
	border: none;
}

.pricing-grid1:nth-child(3) {
	margin-right: 0;
}

.price-value h2 a,
.price-value.two h3 a,
.price-value.three h4 a {
	font-size: 1.8em;
	color: #fff;
}

.price-value,
.price-value.two,
.price-value.three {
	background: #ecf0f1;
	padding: 2.8em 1.8em 2em;
	border-top-left-radius: 0.7em;
	-webkit-border-top-left-radius: 0.7em;
	-o-border-top-left-radius: 0.7em;
	-moz-border-top-left-radius: 0.7em;
	border-top-right-radius: 0.7em;
	-webkit-border-top-right-radius: 0.7em;
	-o-border-top-right-radius: 0.7em;
	-moz-border-top-left-radius: 0.7em;
	position: relative;
}

.price-value {
	background: #ecf0f1;
	border-bottom: 2px solid#f1c40f;
}

.price-value.two {
	background: #ecf0f1;
	border-bottom: 2px solid#f1c40f;
}

.price-value.three {
	background: #2ecc71;
	border-bottom: 2px solid#27ae60;
	color: #fff
}

.price-bg {
	padding: 20px 20px 40px
}

.pricing-grid1 .price-bg h4 span,
.pricing-grid3 .price-bg h4 span {
	color: #f39c12;
	font-size: 120%
}

.pricing-grid2 .price-bg h4 span {
	color: #27ae60;
	font-size: 120%
}

.sale-box.three {
	position: absolute;
	top: 0;
	overflow: hidden;
	height: 130px;
	width: 143px;
	text-align: center;
	z-index: 0;
	right: 0;
	border-top-right-radius: 0.7em;
	-o-border-top-right-radius: 0.7em;
	-moz-border-top-right-radius: 0.7em;
	-webkit-border-top-right-radius: 0.7em;
}

.sale-box span.on_sale {
	font-size: 13px;
	color: #fff;
	text-transform: uppercase;
	padding: 39px 0px 12px 62px;
	;
	width: 287px;
	text-align: center;
	display: block;
	position: absolute;
	left: -47px;
	top: -41px;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-transform: rotate(-44deg);
	-ms-transform: rotate(-44deg);
	transform: rotate(40deg);
	box-shadow: 3px -4px 13px 3px rgba(0, 0, 0, 0.75);
}

.sale-box.three.sale-box span.on_sale {
	background: #27ae60;
}

.cart1,
.cart2,
.cart3 {
	padding-top: 20px;
	display: block;
}

.cart1 a,
.cart2 a,
.cart3 a {
	text-transform: uppercase;
	-webkit-appearance: none;
	color: #fff;
	outline: none;
	padding-left: 0;
	width: 190px;
	padding-right: 0;
}

.cart1 a {
	background: #f1c40f;
	border-color: #f1c40f;
}

.cart2 a {
	background: #f1c40f;
	border-color: #f1c40f
}

.cart3 a {
	background: #2ecc71;
	border-color: #2ecc71
}

.cart1 a:hover,
.cart2 a:hover,
.cart3 a:hover {
	color: #2b2b33;
}


/*----*/


/**
 * Fade-zoom animation for first dialog
 */


/* start state */

.my-mfp-zoom-in #small-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}


/* animate in */

.my-mfp-zoom-in.mfp-ready #small-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}


/* animate out */

.my-mfp-zoom-in.mfp-removing #small-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}


/* Dark overlay, start state */

.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}


/* animate in */

.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}


/* animate out */

.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}

@media(max-width:767px) {
	.pricing-grid1,
	.pricing-grid2,
	.pricing-grid3 {
		width: 55%;
		text-align: center;
		margin: 1em auto;
	}
}

@media(max-width:640px) {
	.pricing-grid1,
	.pricing-grid2,
	.pricing-grid3 {
		width: 65%;
		text-align: center;
		margin: 1em auto;
	}
}

@media(max-width:480px) {
	.pricing-grid1,
	.pricing-grid2,
	.pricing-grid3 {
		width: 81%;
	}
}